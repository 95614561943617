<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="zy-card ova yes-head h-100">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane name="first">
                    <span slot="label">
                        <i class="iconfont icon-shebei"></i>
                        设备运行历史数据
                    </span>
                    <his-device></his-device>
                </el-tab-pane>
                <el-tab-pane name="second">
                    <span slot="label">
                        <i class="iconfont icon-chongzhi2"></i>
                        用户充值历史记录
                    </span>
                    <his-pay></his-pay>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>
<script>
import HisDevice from './compontent/his-device'
import HisPay from './compontent/his-pay'
export default {
    components: { HisDevice, HisPay },

    data() {
        return {
            activeName: 'first'
        }
    },
    created() {},
    methods: {
        handleClick(tab, event) {
            console.log(tab, event)
        }
    }
}
</script>
