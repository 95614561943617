import excel from '@/utils/excel'
export default {
    methods: {
        // 导出
        handleExcelClick(name, tbBody) {
            excel({
                tbHead: this.tbHead,
                tbBody: tbBody ? tbBody : this.tbBody,
                filename: name
            })
        }
    },
    components: {
        excel
    }
}
