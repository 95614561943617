import zyCascader from '@/components/ZyCascader/index.vue'
// import qs from 'qs'
export default {
    components: {
        zyCascader
    },
    data() {
        return {
            // 级联选择器的所有数据
            cascaderData: [],

            // 级联选择器Model
            cascaderModel: {
                apartmentComplexName: null,
                building: null,
                unit: null
                // room: null
            },

            // 级联选择器 选择项
            cascaderModelArr: []
        }
    },
    created() {
        this.getCascaderData()
    },
    methods: {
        // 获取 各层级 数据
        async getCascaderData(level) {
            // 处理数据
            const cascaderModel = this.cascaderModel
            // qs.stringify(this.cascaderModel)
            // 根据 level 发送不同的请求
            let res
            switch (level) {
                case 0:
                    // 小区
                    res = await this.$api.global.getXq(cascaderModel)
                    break

                case 1:
                    // 楼栋
                    res = await this.$api.global.getBuild(cascaderModel)
                    break

                case 2:
                    // 单元
                    res = await this.$api.global.getCellNO(cascaderModel)
                    break
                // case 3:
                //     // 户号
                //     res = await this.$api.global.getHouseNO(cascaderModel)
                //     break
            }
            return res
        },

        // 级联选择器 懒加载 事件
        handleCascaderLazyLoad(node, resolve) {
            // 提取当前 level和value
            const { level, value } = node

            // 获取 各级节点的 key
            const cascaderModelKeys = Object.keys(this.cascaderModel)

            const keyLevel = level ? level - 1 : level

            // 获取 当前节点的key
            const keyActive = cascaderModelKeys[keyLevel]

            // 设置 当前节点的value
            this.cascaderModel[keyActive] = value

            if (level <= 2) {
                // 懒加载
                setTimeout(async () => {
                    // 拿到数据
                    const res = await this.getCascaderData(level)

                    // 错误处理
                    if (res.code != 200)
                        return this.$message.error('获取选择器数据失败')

                    // 数据处理
                    const children = []
                    res.data.forEach(item => {
                        const obj = {
                            value: item.replace('号楼', '').replace('单元', ''),
                            label: item,
                            leaf: level >= 2
                        }
                        children.push(obj)
                    })

                    // 回调
                    resolve(children)
                }, 100)
            } else {
                resolve()
            }
        },

        // 级联选择器点击事件
        handleCascaderChange(cascaderModel) {
            this.cascaderModelArr = cascaderModel
            const cascaderModelKeys = Object.keys(this.cascaderModel)
            cascaderModel.forEach((item, index) => {
                const cascaderModelItem = cascaderModelKeys[index]
                this.cascaderModel[cascaderModelItem] = item
            })
        }
    }
}
