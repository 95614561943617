<template>
    <div class="zykt-main p-1 h-100 box-border">
        <div class="d-flex ai-center jc-between mb-1">
            <div class="d-flex">
                <zy-cascader
                    class="mr-1"
                    ref="zyCascader"
                    expand-trigger="click"
                    :cascaderData="cascaderData"
                    lazy
                    @lazy-load="handleCascaderLazyLoad"
                    @change="handleCascaderChange"
                ></zy-cascader>
                <el-input
                    class="mr-1"
                    v-model="room"
                    placeholder="户号"
                    style="width:120px"
                ></el-input>
                <el-date-picker
                    class="mr-1"
                    v-model="picker"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <el-button type="primary" @click="searchTabData">
                    搜索
                </el-button>
            </div>
            <div class="mr-2">
                <span style="color:red">小提示：</span>
                <span class="font-14 mr-1">高档：</span>
                <i class="iconfont icon-gao font-24 mr-1" style="color:green">
                </i>
                <span class="font-14 mr-1">中档：</span>
                <i
                    class="iconfont icon-zhong font-24 mr-1"
                    style="color:#44AE4C"
                >
                </i>
                <span class="font-14 mr-1">低档：</span>
                <i class="iconfont icon-di font-24 mr-1" style="color:#89BD3E">
                </i>
                <span class="font-14 mr-1">关闭：</span>
                <i class="iconfont icon-didang-hui font-24"> </i>
            </div>
        </div>
        <div class="d-flex mb-1">
            <div v-for="(item, index) in list" :key="index" class="mr-2">
                <span>{{ item.text }}</span>
                <span>{{ item.num }}</span>
                <span>{{ item.unit }}</span>
            </div>
        </div>
        <zy-table :tbHead="tbHead" :tbBody="tbBody" highlightCurrentRow>
            <el-table-column type="expand" slot="column-before">
                <template slot-scope="props">
                    <el-form
                        label-position="left"
                        inline
                        class="demo-table-expand"
                        label-width="100px"
                    >
                        <el-form-item label="NB序列号：">
                            <span>{{ props.row.deviceId }}</span>
                        </el-form-item>
                        <el-form-item label="SIM序列号：">
                            <span>{{ props.row.sim }}</span>
                        </el-form-item>
                        <el-form-item label="设备号：">
                            <span>{{ props.row.fpId }}</span>
                        </el-form-item>
                        <el-form-item label="SN号：">
                            <span>{{ props.row.snId }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <template slot="his-gear" slot-scope="scope">
                <span :type="scope.row.dangWei" class="ta-cente">
                    <i
                        v-if="scope.row.dangWei === '高档'"
                        class="iconfont icon-gao font-24"
                        style="color:green"
                    >
                    </i>
                    <i
                        v-else-if="scope.row.dangWei === '中档'"
                        class="iconfont icon-zhong font-24"
                        style="color:#44AE4C"
                    >
                    </i>
                    <i
                        v-else-if="scope.row.dangWei === '低档'"
                        class="iconfont icon-di font-24"
                        style="color:#89BD3E"
                    >
                    </i>
                    <i v-else class="iconfont icon-didang-hui font-24"> </i>
                    <!-- <span>{{ scope.row.dangWei }}</span> -->
                </span>
            </template>
        </zy-table>
        <zy-page
            class="search-site-page mt-1"
            @current-change="handlePageCurrentChange"
            @size-change="handlePageSizeChange"
            :pageModel="pageModel"
            :total="total"
            :pageSizes="[15, 20, 50, 300]"
        ></zy-page>
    </div>
</template>
<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
import Excel from '@/mixins/excel'
import Cascader from '@/mixins/select'
export default {
    components: { ZyTable },
    mixins: [ZyPage, Excel, Cascader],
    data() {
        return {
            picker: [],

            room: '',
            // 计费状态
            // paySt: '',
            payStOpt: [
                {
                    value: '欠费',
                    label: '欠费'
                },
                {
                    value: '未欠费',
                    label: '未欠费'
                }
            ],
            // 表头
            tbHead: [
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋',
                    width: 50
                },
                {
                    prop: 'unit',
                    label: '单元',
                    width: 50
                },
                {
                    prop: 'room',
                    label: '户号',
                    width: 60
                },
                {
                    prop: 'totalMoney',
                    label: '余额',
                    width: 90
                },
                // {
                //     prop: '',
                //     label: '缴费',
                //     width: 50
                // },
                {
                    prop: 'jifeiStatus',
                    label: '计费状态'
                },
                {
                    prop: 'status',
                    label: '运行状态'
                },
                {
                    prop: 'dangWei',
                    label: '当前档位',
                    slot: 'his-gear'
                },
                {
                    prop: 'high',
                    label: '高档时间(分)',
                    width: 110
                },
                {
                    prop: 'mid',
                    label: '中档时间(分)',
                    width: 110
                },
                {
                    prop: 'low',
                    label: '低档时间(分)',
                    width: 110
                },
                {
                    prop: 'totalDangLiang',
                    label: '总当量'
                },
                {
                    prop: 'zhouQi',
                    label: '周期(分)',
                    width: 80
                },
                {
                    prop: 'recordTime',
                    label: '上传时间',
                    sortable: true,
                    width: 170
                }
                // {
                //     prop: 'deviceId',
                //     label: 'NB序列号',
                //     width: 100,
                //     overflow: true
                // },
                // {
                //     prop: '',
                //     label: 'SIM序列号',
                //     width: 100,
                //     overflow: true
                // },
                // {
                //     prop: 'fpId',
                //     label: '设备号',
                //     width: 100
                // },
                // {
                //     prop: '',
                //     label: '室外温度',
                //     width: 110
                // }
            ],

            // 内容
            tbBody: [],

            list: [
                {
                    icon: 'icon-shebeizaixian',
                    text: '高档：',
                    num: '',
                    unit: '分',
                    flag: 'maxHigh'
                },
                {
                    icon: 'icon-xufei',
                    iconColor: '#3FDF59',
                    text: '中档：',
                    num: '',
                    unit: '分',
                    flag: 'maxLow'
                },
                {
                    icon: 'icon-ic_user_zx',
                    text: '低档：',
                    num: '',
                    unit: '分',
                    flag: 'maxMid'
                }
            ]
        }
    },
    created() {
        this.getHisTabData()
    },
    methods: {
        // 获取表格数据
        async getHisTabData() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.selectData.getHisTabData(
                pageNum,
                pageSize
            )
            this.tbBody = res.data.list
            this.total = res.data.total

            this.list.forEach(item => {
                const keyItem = res.map[item.flag]
                console.log('000', keyItem)
                item.num = keyItem
            })
        },

        // 搜索
        async searchTabData() {
            if (this.cascaderModelArr.length < 4) {
                this.cascaderModel.room = ''
            }
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const apartmentComplexName = this.cascaderModel.apartmentComplexName
            const building = this.cascaderModel.building
            const unit = this.cascaderModel.unit
            const room = this.room
            const startTime = this.picker[0]
            const endTime = this.picker[1]
            const qianFeiStatus = this.paySt
            const res = await this.$api.selectData.getHisTabData(
                pageNum,
                pageSize,
                apartmentComplexName,
                building,
                unit,
                room,
                startTime,
                endTime,
                qianFeiStatus
            )
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 分页回调
        getPageList() {
            return this.searchTabData()
        }
    }
}
</script>
