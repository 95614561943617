<template>
    <div class="zy-cascader">
        <el-cascader
            style="width:200px"
            ref="zyCascader"
            v-model="cascaderModel"
            :options="cascaderOptions"
            :props="cascaderConfig"
            :clearable="clearable"
            :show-all-levels="showAllLevels"
            :collapse-tags="collapseTags"
            @change="handleCascaderChange"
            :placeholder="placeholder"
        ></el-cascader>
    </div>
</template>

<script>
/**
 * options：
 * @param {value} string
 * @param {label} string->名称
 * @param {disabled} Boolean->是否禁用
 * @param {children} Array->子节点
 */
export default {
    name: 'ZyCascader',
    props: {
        // 触发子菜单的方式
        expandTrigger: {
            type: String,
            default: 'hover'
        },

        // 级联选择器数据
        cascaderData: Array,

        // 是否可清空
        clearable: {
            type: Boolean,
            default: false
        },

        // 是否显示多级
        showAllLevels: {
            type: Boolean,
            default: true
        },

        // 是否可以多选
        multiple: {
            type: Boolean,
            default: false
        },

        // 多选时是否折叠tag
        collapseTags: {
            type: Boolean,
            default: false
        },

        // 是否可以选择单个分支
        checkStrictly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择小区/楼栋/单元'
        },

        // 是否动态加载
        lazy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cascaderModel: [],
            cascaderOptions: this.cascaderData,
            cascaderConfig: {
                expandTrigger: this.expandTrigger,
                multiple: this.multiple,
                checkStrictly: this.checkStrictly,
                lazy: this.lazy,
                that: this,
                lazyLoad(node, resolve) {
                    const { that } = this
                    that.$emit('lazy-load', node, resolve)
                }
            }
        }
    },
    methods: {
        // model改变时触发
        handleCascaderChange(value) {
            this.$emit('change', value)
        }
    }
}
</script>
